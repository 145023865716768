












































































































import Vue, { PropType, VueConstructor } from "vue";
import { createNamespacedHelpers } from "vuex";

import clientStoreModule from "@/store/modules/client";
import locationStoreModule from "@/store/modules/location";
import { Business, Client, Role, _Location } from "@/types";
import { PHONE_REGEX } from "@/util/constants";

const { mapActions: clientActions } =
  createNamespacedHelpers("UPDATE_CLIENT_FORM");

const { mapActions: locationActions } = createNamespacedHelpers(
  "UPDATE_CLIENT_LOCATIONS"
);

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        imageField: HTMLInputElement;
      };
    }
  >
).extend<any, any, any, any>({
  name: "UpdateClientForm",
  props: {
    client: {
      type: Object as PropType<Client>,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
      fullName: "",
      phone: "",
      email: "",
      dob: undefined as undefined | Date | string,
      gender: "",
      notes: "",
      address: "",
      fullNameRules: [(v: string) => !!v || "Full Name is required"],
      phoneRules: [
        /*(v: string) => !!v || "Phone is required" */
        (v: string) =>
          PHONE_REGEX.test(v) || "A valid Phone number is required",
      ],
      emailRules: [(v: string) => !!v || "Email is required"],
      dobRules: [(v: string) => !!v || "Date of Birth is required"],
      genderRules: [(v: string) => !!v || "Gender is required"],
      addressRules: [],
      locations: [] as string[],
      image: undefined as undefined | File,
    };
  },
  mounted() {
    this.resetForm();
  },
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...clientActions(["updateClient"]),
    ...locationActions(["fetchLocationList"]),
    validateForm() {
      const valid = (
        this.$refs.clientForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;
      const formData = new FormData();
      formData.append("businessId", (this.role.business as Business)._id);
      formData.append("fullName", this.fullName);
      formData.append("phone", this.phone);
      formData.append("email", this.email);
      formData.append("notes", this.notes);
      formData.append("address", this.address);
      formData.append("gender", this.gender);
      formData.append("dob", this.dob as string);
      if (this.image) formData.append("file", this.image as File);

      this.updateClient({ id: this.client._id, client: formData }).then(
        (client) => {
          if (client) this.$emit("data-saved");
        }
      );
    },
    resetForm() {
      this.fullName = this.client.fullName;
      this.phone = this.client.phone;
      this.dob = this.client.dob;
      this.email = this.client.email;
      this.gender = this.client.gender;
      this.notes = this.client.notes;
      this.address = this.client.address;
      this.image = undefined;
      (
        this.$refs.clientForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
    searchLocation(q: string) {
      if (q) {
        const bid = (this.role.business as Business)._id;
        let params = `?businessId=${bid}&q=${q}`;
        this.fetchLocationList(params).then((locationPage) => {
          if (locationPage) {
            this.locations = locationPage.docs.map((l: _Location) => l.name);
          }
        });
      }
    },
    upload(image: File) {
      this.image = image;
    },
    dropHandler(ev: DragEvent) {
      const file = ev.dataTransfer?.files[0] as File;

      this.image = file;
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("UPDATE_CLIENT_FORM")) {
      this.$store.registerModule("UPDATE_CLIENT_FORM", clientStoreModule);
    }
    if (!this.$store.hasModule("UPDATE_CLIENT_LOCATIONS")) {
      this.$store.registerModule(
        "UPDATE_CLIENT_LOCATIONS",
        locationStoreModule
      );
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("UPDATE_CLIENT_FORM");
    this.$store.unregisterModule("UPDATE_CLIENT_LOCATIONS");
  },
});
