






























































































































































































import Vue from "vue";
import clientCategoryStoreModule from "@/store/modules/clientCategories";
import clientStoreModule from "@/store/modules/client";
import { createNamespacedHelpers } from "vuex";
import { Business, ClientCategory, Role } from "@/types";

const { mapActions: ClientCategoryActions, mapGetters: ClientCategoryGetters } =
  createNamespacedHelpers("CLIENTCATEGORY");

const { mapActions: ClientActions, mapGetters: ClientGetters } =
  createNamespacedHelpers("CLIENT_LIST");

const rules = {
  category_name: [(v: any) => !!v || "Category Name is required"],
  category_desc: [(v: any) => !!v || "Category Description is required"],
};

export default Vue.extend<any, any, any, any>({
  name: "ClientCategoryList",
  data: () => ({
    dialog: false,
    dialogNotify: false,
    viewCategoryDialog: false,
    edittingCategory: null,
    editting: false,
    clientsearch: "",
    topupClients: {
      clientIds: [],
    },
    headers: [
      {
        text: "Category Name",
        value: "category_name",
        sortable: false,
      },
      {
        text: "Description",
        sortable: false,
        value: "category_description",
      },
      { text: "Clients", value: "clients", sortable: false },
      { text: "", value: "actions" },
    ],
    clientheaders: [
      { text: "Name", value: "fullName", sortable: false },
      { text: "Email Address", value: "email", sortable: false },
      { text: "", value: "actions", sortable: false },
    ],
    newCategory: {
      category_name: "",
      category_description: "",
      clientIds: [],
    },
    options: {} as { page: number },
    rules: rules,
    valid: false,
  }),
  created() {
    this.fetchClientCategories();
    this.fetchClients();
  },
  watch: {
    role() {
      if (this.role) {
        const params = `?businessId=${(this.role.business as Business)?._id}`;
        this.fetchClientCategoryList(params);
        this.fetchClientList(params);
      }
    },
    options: {
      handler() {
        this.fetchClientCategories();
        this.fetchClients();
      },
      deep: true,
    },
  },
  computed: {
    ...ClientCategoryGetters(["clientCategoryPage"]),
    ...ClientGetters(["clientPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...ClientCategoryActions([
      "fetchClientCategoryList",
      "createCategory",
      "removeClientFromCategory",
      "addClientToCategory",
    ]),
    ...ClientActions(["fetchClientList"]),
    fetchClientCategories() {
      if (this.role) {
        const params = `?businessId=${(this.role.business as Business)?._id}`;
        this.fetchClientCategoryList(params);
      }
    },
    fetchClients() {
      if (this.role) {
        const params = `?businessId=${(this.role.business as Business)?._id}`;
        this.fetchClientList(params);
      }
    },
    closeNewCategoryDialog() {
      this.dialog = false;
      this.resetNewCategoryForm();
    },
    saveNewCategory() {
      if (this.editting) {
        this.editting = false;
        this.closeNewCategoryDialog();
      } else {
        const data = {
          ...this.newCategory,
          businessId: (this.role.business as Business)?._id,
        };
        this.createCategory({ ...data }).then(() => {
          this.closeNewCategoryDialog();
          if (this.role) {
            const params = `?businessId=${
              (this.role.business as Business)?._id
            }`;
            this.fetchClientCategoryList(params);
          }
        });
      }
    },
    resetNewCategoryForm() {
      this.newCategory = {
        category_name: "",
        category_description: "",
        clientIds: [],
      };
    },
    removeClient(categoryId: string, clientId: string) {
      this.removeClientFromCategory({ categoryId, clientId });
    },
    addClients(categoryId: string, clientIds: string[]) {
      this.addClientToCategory({ categoryId, clientIds }).then(
        () => (this.topupClients.clientIds = [])
      );
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    viewCategory(value: any) {
      this.edittingCategory = value;
      this.viewCategoryDialog = true;
    },
    startEdit(category: ClientCategory) {
      this.editting = true;
      this.newCategory = { ...category, clientIds: [] };
      this.dialog = true;
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("CLIENTCATEGORY")) {
      this.$store.registerModule("CLIENTCATEGORY", clientCategoryStoreModule);
    }

    if (!this.$store.hasModule("CLIENT_LIST")) {
      this.$store.registerModule("CLIENT_LIST", clientStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("CLIENTCATEGORY");
    this.$store.unregisterModule("CLIENT_LIST");
  },
});
