

































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import waitlistStoreModule from "@/store/modules/waitlist";
import employeeStoreModule from "@/store/modules/employee";
import serviceStoreModule from "@/store/modules/service";
import clientStoreModule from "@/store/modules/client";
import { Business, Role } from "@/types";

const { mapActions: employeeActions, mapGetters: employeeGetters } =
  createNamespacedHelpers("EMPLOYEES_");

const { mapActions: serviceActions, mapGetters: serviceGetters } =
  createNamespacedHelpers("SERVICES_");

const { mapActions: clientActions, mapGetters: clientGetters } =
  createNamespacedHelpers("CLIENTS_");

const { mapActions: waitlistActions } = createNamespacedHelpers("WAITLIST_");

export default Vue.extend<any, any, any, any>({
  name: "WaitlistForm",
  data() {
    return {
      valid: false,
      date: "",
      time: "",
      employeeId: "",
      selectedServiceId: "",
      clientId: "",
      location: "",
      notes: "",
      dateRules: [(v: string) => !!v || "Date is required"],
      timeRules: [(v: string) => !!v || "Time is required"],
      staffRules: [(v: string) => !!v || "Staff is required"],
      serviceRules: [(v: string) => !!v || "Service is required"],
      clientRules: [(v: string) => !!v || "Client is required"],
    };
  },
  mounted() {
    this.resetForm();
  },
  created() {
    this.fetchData();
  },
  watch: {
    role: "fetchData",
  },
  computed: {
    ...clientGetters(["clientPage"]),
    ...employeeGetters(["employeePage"]),
    ...serviceGetters(["servicePage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...waitlistActions(["createWaitlist", "updateWaitlist"]),
    ...employeeActions(["fetchEmployeeList"]),
    ...serviceActions(["fetchServiceList"]),
    ...clientActions(["fetchClientList"]),
    validateForm() {
      const valid = (
        this.$refs.waitlistForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;
      // Logic for saving waitlist
      const startDate = new Date(this.date);

      startDate.setHours(+this.time.split(":")[0]);
      startDate.setMinutes(+this.time.split(":")[1]);

      const businessId = (this.role?.business as Business)?._id;

      this.createWaitlist({
        serviceId: this.selectedServiceId,
        businessId,
        employeeId: this.employeeId,
        clientId: this.clientId,
        notes: this.notes,
        startDate: startDate.toISOString(),
      }).then((waitlist) => {
        if (waitlist) this.$emit("data-saved", true);
      });
    },
    resetForm() {
      this.date = "";
      this.time = "";
      this.employeeId = "";
      this.selectedServiceId = "";
      this.notes = "";
      (
        this.$refs.waitlistForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
    cancel() {
      this.$emit("cancel", true);
    },
    addClient() {
      this.$emit("add-client", true);
    },
    deleteSlot() {
      //
    },
    searchService() {
      //
    },
    fetchData() {
      if (this.role) {
        const params = `?businessId=${(this.role.business as Business)._id}`;
        this.fetchEmployeeList(params);
        this.fetchServiceList(params);
        this.fetchClientList(params);
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("WAITLIST_")) {
      this.$store.registerModule("WAITLIST_", waitlistStoreModule);
    }
    if (!this.$store.hasModule("EMPLOYEES_")) {
      this.$store.registerModule("EMPLOYEES_", employeeStoreModule);
    }
    if (!this.$store.hasModule("SERVICES_")) {
      this.$store.registerModule("SERVICES_", serviceStoreModule);
    }
    if (!this.$store.hasModule("CLIENTS_")) {
      this.$store.registerModule("CLIENTS_", clientStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("EMPLOYEE_LIST");
    this.$store.unregisterModule("WAITLIST_");
    this.$store.unregisterModule("SERVICES_");
    this.$store.unregisterModule("CLIENTS_");
  },
});
