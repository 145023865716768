




















































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import WaitListForm from "./WaitListForm.vue";

import waitlistStoreModule from "@/store/modules/waitlist";
import ClientForm from "../ClientForm.vue";
import WaitlistDetail from "./WaitlistDetail.vue";
import { Business, Role } from "@/types";

const { mapActions: waitlistActions, mapGetters: waitlistGetters } =
  createNamespacedHelpers("WAITLIST");

export default Vue.extend<any, any, any, any>({
  components: { WaitListForm, ClientForm, WaitlistDetail },
  name: "WaitList",
  data: () => ({
    waitlist: undefined,
    showWaitlistDetail: false,
    showWaitlistDialog: false,
    showClientDialog: false,
    serviceId: "",
    headers: [
      {
        text: "Client",
        value: "client",
      },
      { text: "Serice", value: "_id" },
      { text: "No. of clients", value: "numberOfClients" },
      { text: "Action", value: "action" },
    ],
    options: {} as { page: number },
  }),
  watch: {
    role() {
      this.fetchData();
    },
    options: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
  },
  created() {
    this.fetchData();
  },
  computed: {
    ...waitlistGetters(["waitlistAggregate", "waitlistPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...waitlistActions(["fetchWaitlistAggregate", "fetchWaitlistList"]),
    resetForm() {
      (
        this.$refs.waitListForm as Element & {
          resetForm: () => void;
        }
      )?.resetForm();
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    viewWaitlist(item: any) {
      this.serviceId = item._id._id;
      this.showWaitlistDetail = true;
    },
    waitlistSaved() {
      this.fetchWaitlistAggregate();
      this.showWaitlistDialog = false;
    },
    fetchData() {
      if (this.role) {
        let params = `?businessId=${(this.role.business as Business)?._id}`;
        this.fetchWaitlistAggregate(params);
        params += `&page=${this.options.page || 1}`;
        this.fetchWaitlistList(params);
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("WAITLIST")) {
      this.$store.registerModule("WAITLIST", waitlistStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("WAITLIST");
  },
});
