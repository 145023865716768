var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.service)?_c('div',[_c('v-dialog',{attrs:{"width":"440"},model:{value:(_vm.showRemoveDialog),callback:function ($$v) {_vm.showRemoveDialog=$$v},expression:"showRemoveDialog"}},[(_vm.waitlist)?_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Confirm to delete client from waitlist ")]),_c('v-card-text',[_vm._v(" This action will delete "+_vm._s(_vm.waitlist.client.fullName)+" from waitlist on "+_vm._s(_vm._f("readableDate")(_vm.waitlist.startDate,"D/MM/YYYY HH:mm A"))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.showRemoveDialog = false}}},[_vm._v(" Disagree ")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.deleteWaitlist(("/" + (_vm.waitlist._id)));
            _vm.showRemoveDialog = false;}}},[_vm._v(" Agree ")])],1)],1):_vm._e()],1),_c('v-dialog',{attrs:{"width":"520"},model:{value:(_vm.showNotesDialog),callback:function ($$v) {_vm.showNotesDialog=$$v},expression:"showNotesDialog"}},[(_vm.waitlist)?_c('v-card',[_c('v-card-title',{staticStyle:{"justify-content":"space-between"}},[_c('span',{staticClass:"text-h5"},[_vm._v("Appointment Notes")]),_c('v-btn',{staticStyle:{"float":"right","color":"#fff"},attrs:{"color":"#252733"},on:{"click":function($event){_vm.editingNotes = true}}},[_c('v-icon',[_vm._v("mdi-notebook-edit-outline")]),_vm._v(" Edit notes ")],1)],1),_c('v-card-text',[(_vm.editingNotes)?_c('v-textarea',{attrs:{"label":"Notes","placeholder":"Enter notes"},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}}):_c('p',[_vm._v(_vm._s(_vm.waitlist.notes))])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.showNotesDialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showNotesDialog = false;
            _vm.saveNotes(_vm.waitlist._id);}}},[_vm._v(" Save ")])],1)],1):_vm._e()],1),_c('v-row',{staticClass:"waitlist-details-header"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('hide-details', true)}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_vm._v(" Waitlist - "+_vm._s(_vm.service.name)+" ")],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{staticClass:"search-field",attrs:{"items":_vm.waitlistPage.docs,"item-text":"client.fullName","item-value":"_id","height":"20","label":"Search Waitlist"},model:{value:(_vm.waitlist),callback:function ($$v) {_vm.waitlist=$$v},expression:"waitlist"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" ")])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.waitlistPage.docs,"options":_vm.options,"server-items-length":_vm.waitlistPage.total,"no-data-text":"No items in waitlist to display"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.client",fn:function(ref){
            var item = ref.item;
return [_c('v-avatar',[_c('img',{attrs:{"src":require("../../assets/avatar.png"),"alt":item.client.fullName}})]),_vm._v("   "+_vm._s(item.client.fullName)+" ")]}},{key:"item.duration",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("duration")(item.createdAt))+" ")]}},{key:"item.employee",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.employee.fullName)+" ")]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [(item.status === 'completed')?_c('v-chip',{staticStyle:{"color":"#4ee66f"},attrs:{"color":"#d3f9db"}},[_vm._v(" "+_vm._s(item.status)+" ")]):_vm._e(),(item.status === 'pending')?_c('v-chip',{staticStyle:{"color":"#f21416"},attrs:{"color":"#facccc"}},[_vm._v(" "+_vm._s(item.status)+" ")]):_vm._e(),_vm._v("   "),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3 mr-4",attrs:{"elevation":"1","fab":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.showRemoveDialog = true;
                _vm.waitlist = item;}}},[_vm._v(" Remove ")])],1),_c('v-list-item',[_c('v-list-item-title',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.notes = item.notes;
                _vm.waitlist = item;
                _vm.showNotesDialog = true;}}},[_vm._v(" View Notes ")])],1)],1)],1)]}}],null,true)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }