























import Vue from "vue";

import ClientList from "@/components/client/ClientList.vue";
import ActionButtons from "@/components/ActionButtons.vue";
import ClientCategoryList from "@/components/client/ClientCategoryList.vue";
import WaitList from "@/components/employee/WaitList.vue";

export default Vue.extend<any, any, any, any>({
  name: "Clients",
  components: { ClientList, ActionButtons, ClientCategoryList, WaitList },
  data: () => ({
    tab: 0,
  }),
});
