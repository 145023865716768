var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.showWaitlistDialog),callback:function ($$v) {_vm.showWaitlistDialog=$$v},expression:"showWaitlistDialog"}},[_c('wait-list-form',{ref:"waitListForm",on:{"cancel":function($event){_vm.showWaitlistDialog = false},"data-saved":_vm.waitlistSaved,"add-client":function($event){_vm.showWaitlistDialog = false;
        _vm.showClientDialog = true;
        _vm.$refs.clientForm.resetForm();}}})],1),_c('v-dialog',{attrs:{"eager":"","width":"550"},model:{value:(_vm.showClientDialog),callback:function ($$v) {_vm.showClientDialog=$$v},expression:"showClientDialog"}},[_c('client-form',{ref:"clientForm",on:{"data-saved":function($event){_vm.showClientDialog = false},"cancel":function($event){_vm.showClientDialog = false;
        _vm.showWaitlistDialog = true;},"add-client":function($event){_vm.showClientDialog = true}}})],1),(!_vm.showWaitlistDetail)?_c('div',[_c('v-row',{staticClass:"waitlist-header"},[_c('v-col',{attrs:{"cols":"4"}},[_vm._v("Waitlist")]),_c('v-col',{attrs:{"md":"4"}},[_c('v-autocomplete',{staticClass:"search-field",attrs:{"items":_vm.waitlistPage.docs,"item-text":"client.fullName","item-value":"_id","height":"20","label":"Search Waitlist"},on:{"change":_vm.viewWaitlist},model:{value:(_vm.waitlist),callback:function ($$v) {_vm.waitlist=$$v},expression:"waitlist"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"md":"4"}},[_c('v-btn',{attrs:{"elevation":"0"},on:{"click":function($event){_vm.resetForm();
            _vm.showWaitlistDialog = true;}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add client ")],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.waitlistAggregate,"options":_vm.options,"no-data-text":"No items on waitlist to display"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.client",fn:function(ref){
            var item = ref.item;
return _vm._l((item.list),function(li,k){return _c('v-avatar',{key:k},[_c('img',{attrs:{"src":require("../../assets/avatar.png"),"alt":li.client.fullName}})])})}},{key:"item._id",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item._id.name)+" ")]}},{key:"item.numberOfClients",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.list.length)+" ")]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.viewWaitlist(item)}}},[_vm._v("View")])]}}],null,true)})],1):_c('div',[(_vm.serviceId)?_c('waitlist-detail',{attrs:{"serviceId":_vm.serviceId},on:{"hide-details":function($event){_vm.showWaitlistDetail = false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }