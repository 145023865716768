var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"eager":"","width":"550"},model:{value:(_vm.showClientDialog),callback:function ($$v) {_vm.showClientDialog=$$v},expression:"showClientDialog"}},[(_vm.showClientDialog)?_c('client-form',{ref:"clientForm",on:{"data-saved":_vm.closeModal,"cancel":function($event){_vm.showClientDialog = false}}}):_vm._e()],1),_c('v-dialog',{attrs:{"width":"340"},model:{value:(_vm.showDeleteDialog),callback:function ($$v) {_vm.showDeleteDialog=$$v},expression:"showDeleteDialog"}},[(_vm.client)?_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Confirm to delete client ")]),_c('v-card-text',[_vm._v(" This action will delete "+_vm._s(_vm.client.fullName)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.showDeleteDialog = false}}},[_vm._v(" Disagree ")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.deleteClient(("/" + (_vm.client._id)));
            _vm.showDeleteDialog = false;}}},[_vm._v(" Agree ")])],1)],1):_vm._e()],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.editClientDialog),callback:function ($$v) {_vm.editClientDialog=$$v},expression:"editClientDialog"}},[_c('update-client-form',{attrs:{"client":_vm.client},on:{"cancel":function($event){_vm.editClientDialog = false},"data-saved":_vm.closeModal}})],1),_c('v-row',[_c('v-col',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{staticClass:"filters"},[_c('v-btn',{attrs:{"rounded":"","icon":""}},[_c('v-icon',[_vm._v("mdi-filter-variant")])],1),_c('v-autocomplete',{staticClass:"search-field",attrs:{"items":_vm.clientPage.docs,"item-text":"fullName","item-value":"_id","height":"20","label":"Search Clients"},on:{"change":_vm.viewClient,"update:search-input":_vm.searchClient},model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}})],1),_c('div',{staticClass:"extra-btns"},[_c('v-btn',{attrs:{"elevation":"0","small":""},on:{"click":function($event){_vm.showClientDialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add Client ")],1),_vm._v("   "),_c('v-btn',{attrs:{"elevation":"0","small":"","disabled":_vm.exporting},on:{"click":_vm._export}},[_c('v-icon',[_vm._v("mdi-export-variant")]),_vm._v(" Export ")],1)],1)])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.clientPage.docs,"options":_vm.options,"server-items-length":_vm.clientPage.total,"no-data-text":"No clients to display"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.fullName",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"flex-start","align-items":"center"}},[(item.avatar)?_c('v-avatar',[_c('img',{attrs:{"src":(_vm.apiUrl + "/v1/file/" + (item.avatar.filename)),"alt":""}})]):_c('v-avatar',[_c('img',{attrs:{"src":require("../../assets/avatar.png"),"alt":item.fullName}})]),_c('span',{staticStyle:{"white-space":"initial","margin-left":"5px"}},[_vm._v(" "+_vm._s(item.fullName)+" ")])],1)]}},{key:"item.phone",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("maskPhone")(item.phone))+" ")]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","to":("/client/" + (item._id)),"rounded":""}},[_vm._v(" View ")]),_vm._v("   "),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3 mr-4",attrs:{"elevation":"1","fab":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.client = item;
              _vm.editClientDialog = true;}}},[_c('v-list-item-title',{staticStyle:{"cursor":"pointer"}},[_vm._v(" Edit ")])],1),_c('v-list-item',{on:{"click":function($event){_vm.client = item;
              _vm.editClientDialog = true;}}},[_c('v-list-item-title',{staticStyle:{"cursor":"pointer"}},[_vm._v(" Add notes ")])],1),_c('v-list-item',{on:{"click":function($event){_vm.client = item;
              _vm.editClientDialog = true;}}},[_c('v-list-item-title',{staticStyle:{"cursor":"pointer"}},[_vm._v(" Add photos ")])],1),_c('v-list-item',[_c('v-list-item-title',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.client = item;
                _vm.showDeleteDialog = true;}}},[_vm._v(" Delete ")])],1)],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }