
















































































































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";

import waitlistStoreModule from "@/store/modules/waitlist";
import serviceStoreModule from "@/store/modules/service";
import { Service } from "@/types";

const { mapActions: serviceActions } = createNamespacedHelpers("SERVICE_");

const { mapActions: waitlistActions, mapGetters: waitlistGetters } =
  createNamespacedHelpers("WAITLIST_");

export default Vue.extend<any, any, any, any>({
  name: "WaitlistDetail",
  props: {
    serviceId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    waitlist: undefined,
    showRemoveDialog: false,
    showNotesDialog: false,
    editingNotes: false,
    notes: "",
    headers: [
      {
        text: "Client",
        value: "client",
      },
      { text: "Duration", value: "duration" },
      { text: "Staff", value: "employee" },
      { text: "Action", value: "action" },
    ],
    options: {} as { page: number },
  }),
  watch: {
    options: {
      handler() {
        this.fetchWaitlist();
      },
      deep: true,
    },
  },
  filters: {
    duration(date: string): string {
      const d = moment().diff(date, "days");
      if (d < 1) return `less than a day`;
      return `${d} day(s)`;
    },
  },
  created() {
    this.fetchService(`?serviceId=${this.serviceId}`);
    this.fetchWaitlist();
  },
  computed: {
    ...waitlistGetters(["waitlistPage"]),
    service(): Service {
      return this.$store.getters["SERVICE_/getService"](this.serviceId);
    },
  },
  methods: {
    ...waitlistActions(["fetchWaitlistList", "updateWaitlist"]),
    ...serviceActions(["fetchService"]),
    saveNotes(id: string) {
      this.updateWaitlist({ id, data: { notes: this.notes } }).then((w) => {
        if (w) this.showNotesDialog = false;
      });
    },
    fetchWaitlist() {
      const params = `?serviceId=${this.serviceId}&page=${
        this.options.page || 1
      }`;
      this.fetchWaitlistList(params);
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("WAITLIST_")) {
      this.$store.registerModule("WAITLIST_", waitlistStoreModule);
    }
    if (!this.$store.hasModule("SERVICE_")) {
      this.$store.registerModule("SERVICE_", serviceStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("WAITLIST_");
    this.$store.unregisterModule("SERVICE_");
  },
});
