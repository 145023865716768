import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { Waitlist, Page } from "@/types";

type WaitlistState = {
  waitlistPage: Page<Waitlist>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  waitlistAggregate: any[];
};

const waitlist: Module<WaitlistState, unknown> = {
  namespaced: true,
  state: () => ({
    waitlistPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
    waitlistAggregate: [],
  }),
  getters: {
    getWaitlist: (state) => (waitlistId: string) =>
      state.waitlistPage.docs.find((c) => c._id === waitlistId),
    waitlistPage: (state) => state.waitlistPage,
    waitlistAggregate: (state) => state.waitlistAggregate,
  },
  mutations: {
    ADD_WAITLIST: (state, _waitlist) => {
      let idx = -1;
      state.waitlistPage.docs.map((a, i) => {
        if (a._id === _waitlist._id) idx = i;
      });
      if (idx === -1) state.waitlistPage.docs.push(_waitlist);
      else Vue.set(state.waitlistPage.docs, idx, _waitlist);
    },
    SET_WAITLIST_PAGE: (state, list) => {
      state.waitlistPage = list;
    },
    REMOVE_WAITLIST(state, waitlist) {
      let idx = -1;
      state.waitlistPage.docs.map((a, i) => {
        if (a._id === waitlist._id) idx = i;
      });
      if (idx > -1) state.waitlistPage.docs.splice(idx, 1);
    },
    SET_WAITLIST_AGGREGATE(state, aggregate) {
      state.waitlistAggregate = aggregate;
    },
  },
  actions: {
    fetchWaitlist(context, params = "") {
      api
        .get(`/v1/waitlist${params}`)
        .then((response) => {
          context.commit("ADD_WAITLIST", response.data.waitlist);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchWaitlistList(context, params = "") {
      api
        .get(`/v1/waitlist${params}`)
        .then((response) => {
          context.commit("SET_WAITLIST_PAGE", response.data.waitlistPage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchWaitlistAggregate(context, params = "") {
      api
        .get(`/v1/waitlist/aggregate${params}`)
        .then((response) => {
          context.commit(
            "SET_WAITLIST_AGGREGATE",
            response.data.waitlistAggregate
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createWaitlist(context, payload) {
      return await api
        .post(`/v1/waitlist`, payload)
        .then((response) => {
          context.commit("ADD_WAITLIST", response.data.waitlist);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Waitlist created",
            },
            { root: true }
          );
          return response.data.waitlist;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateWaitlist(context, data: { id: string; waitlist: Waitlist }) {
      return await api
        .put(`/v1/waitlist/${data.id}`, data.waitlist)
        .then((response) => {
          context.commit("ADD_WAITLIST", response.data.waitlist);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Waitlist updated",
            },
            { root: true }
          );
          return response.data.waitlist;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deleteWaitlist(context, id) {
      api
        .delete(`/v1/waitlist${id}`)
        .then((response) => {
          context.commit("REMOVE_WAITLIST", response.data.waitlist);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Waitlist deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default waitlist;
